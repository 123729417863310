// Получить модальный
var modal = document.getElementById("myModal");
const overlay = document.getElementById("overlay");
const modalForm = document.getElementById("modalForm");
const modalFormClose = document.getElementById("modalFormClose");

// Получить кнопку, которая открывает модальный
var btn = document.getElementById("modalBtn");

// Получить элемент <span>, который закрывает модальный
var span = document.getElementsByClassName("close")[0];

// Когда пользователь нажимает на кнопку, откройте модальный
btn.onclick = function() {
  modal.style.display = "block";
}

// Когда пользователь нажимает на <span> (x), закройте модальное окно
span.onclick = function() {
  modal.style.display = "none";
  overlay.classList.remove("is-show");
  document.body.style.overflow = 'auto';
}

// Когда пользователь щелкает в любом месте за пределами модального, закройте его
window.onclick = function(event) {
  if (event.target.id === "overlay") {
    modal.style.display = "none";
    overlay.classList.remove("is-show");
    modalForm.style.display = "none";
    modalFormSuccess.style.display = "none";
		modalFormFill.style.display = "none";
		modalFormError.style.display = "none";
    document.body.style.overflow = 'auto';
  }
}

btn.addEventListener("click", () => {
    overlay.classList.add("is-show");
    document.body.style.overflow = 'hidden';
})


window.addEventListener("keydown", (event) => {
    if (event.key == "Escape") {
        span.click()
        modalFormClose.click()
    }
})